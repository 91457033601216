<template>
  <main class="mx-auto my-auto">
    <h2 class="mb-5">Not Found, Error 404</h2>
    <p class="mb-0">The page you are looking for no longer exists. Perhaps you can return back to the <router-link to="/">homepage</router-link> and see if you can find what you are looking for.</p>
  </main>
</template>

<script>
export default {
  name: 'Error'
}
</script>